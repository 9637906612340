import { useState } from 'react'
import Image from 'next/image'
import { Message, ToolInvocation } from 'ai'
import {
  Calculator,
  ChevronDown,
  Copy,
  ExternalLink,
  Lightbulb,
  Loader2,
  RefreshCw,
  Search,
  User2,
  Wrench
} from 'lucide-react'
import { Atom } from 'react-loading-indicators'

import { useCopyToClipboard } from '@/app/components/hooks/use-copy-to-clipboard'
import Markdown from '@/app/components/markdown'
import { Avatar, AvatarFallback, AvatarImage } from '@/app/components/ui/avatar'
import { Button } from '@/app/components/ui/button'
import { Attachment, ConciergeData, NexusState } from '@/app/lib/types'

// Add this function near the top of the file, after the imports
const displayToolName = (toolName: string): string => {
  const toolNameMap: { [key: string]: string } = {
    Tavily: 'Web Search',
    Calculator: 'Calculator'
    // Add more mappings as needed
  }
  return toolNameMap[toolName] || toolName
}

const getToolStatusMessage = (toolName: string, args: any): string => {
  switch (toolName) {
    case 'Tavily':
      if (args?.query) {
        return `Searching for "${args.query}"...`
      } else {
        return 'Searching for ...'
      }
    case 'Calculator':
      return `Calculating ${args?.expression || 'expression'}...`
    default:
      return 'Processing...'
  }
}

// Add this function at the top of the file
const generateLuckyLink = (query: string) => {
  return {
    title: query,
    url: `https://duckduckgo.com/?q=${encodeURIComponent('\\' + query)}`
  }
}

// Main message components
export const UserMessage: React.FC<{
  message: Message
  nexusState: NexusState
}> = ({ message, nexusState }) => {
  const { user } = nexusState

  let attachments: Attachment[] = []
  if (
    typeof message.data === 'object' &&
    message.data !== null &&
    'attachments' in message.data &&
    Array.isArray(message.data.attachments)
  ) {
    attachments = message.data.attachments.map((att: any) => ({
      uuid: att.uuid,
      name: att.name,
      size: att.size,
      mimeType: att.mimeType,
      cdnUrl: att.cdnUrl,
      isImage: att.isImage,
      userId: att.userId
    }))
  }

  return (
    <div className="user-message flex items-start justify-end gap-2 px-2 py-2 md:gap-4 md:px-5">
      <div className="relative max-w-[85%] rounded-lg bg-blue-200 p-3 text-sm dark:bg-blue-800 md:max-w-[80%] md:p-4">
        <div className="whitespace-pre-line text-blue-900 dark:text-blue-100">
          {message.content}
        </div>

        {attachments.length > 0 && (
          <div className="mt-3 border-t border-blue-300 pt-3 dark:border-blue-700">
            <h4 className="mb-2 text-xs font-medium text-blue-700 dark:text-blue-300">
              Attachments
            </h4>
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-3">
              {attachments.map((attachment, index) => (
                <div
                  key={index}
                  className="flex items-center overflow-hidden rounded-lg bg-blue-100 p-2 dark:bg-blue-900"
                >
                  <span className="truncate text-xs text-blue-700 dark:text-blue-300">
                    {attachment.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <Avatar className="h-8 w-8 rounded-full md:h-10 md:w-10">
        {user && user.imageUrl ? (
          <AvatarImage src={user.imageUrl} alt={user.fullName || 'You'} />
        ) : (
          <AvatarFallback>
            <User2 className="h-4 w-4 md:h-6 md:w-6" />
          </AvatarFallback>
        )}
      </Avatar>
    </div>
  )
}

export const AIMessage: React.FC<{
  message: Message
  nexusState: NexusState
}> = ({ message, nexusState }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 })
  const { isLoading, reload } = nexusState

  return (
    <div className="ai-message flex items-start justify-start gap-2 px-2 py-2 md:gap-4 md:px-5">
      <Avatar className="h-8 w-8 md:h-10 md:w-10">
        <AvatarImage src="/img/bubbletar-cora-purple.svg" alt="Cora" />
        <AvatarFallback>C</AvatarFallback>
      </Avatar>
      <div className="bg-primary-50 dark:bg-primary-900 group relative flex max-w-[85%] flex-col justify-between gap-2 rounded-lg p-3 md:max-w-[80%] md:p-4">
        <div className="flex justify-between gap-2">
          {isLoading && message.content === '' ? (
            <div className="scale-75 transform">
              <Atom color="#4F46E5" size="small" textColor="" easing="ease-in-out" />
            </div>
          ) : (
            <Markdown content={message.content} />
          )}
        </div>

        {/* Only show buttons when not loading */}
        {!isLoading && (
          <div className="mt-2 flex justify-start space-x-2">
            <Button
              size="sm"
              variant="ghost"
              onClick={() => copyToClipboard(message.content)}
              className="text-primary-700 hover:bg-primary-200 dark:text-primary-300 dark:hover:bg-primary-700 h-6 text-xs"
            >
              {isCopied ? (
                <>
                  <Copy className="mr-1 h-3 w-3" />
                  Copied!
                </>
              ) : (
                <>
                  <Copy className="mr-1 h-3 w-3" />
                  Copy
                </>
              )}
            </Button>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => reload()}
              className="text-primary-700 hover:bg-primary-200 dark:text-primary-300 dark:hover:bg-primary-700 h-6 text-xs"
            >
              <RefreshCw className="mr-1 h-3 w-3" />
              Regenerate
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const ToolInvocationDisplay: React.FC<{
  toolInvocation: ToolInvocation
  nexusState: NexusState
}> = ({ toolInvocation, nexusState }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isDeveloper = nexusState.user?.publicMetadata?.developer === true

  const getToolIcon = (toolName: string) => {
    switch (toolName) {
      case 'Tavily':
        return <Search className="h-4 w-4" />
      case 'Calculator':
        return <Calculator className="h-4 w-4" />
      default:
        return <Wrench className="h-4 w-4" />
    }
  }

  return (
    <div className="border-primary-200 dark:border-primary-700 bg-primary-100 dark:bg-primary-900 rounded-md border p-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          {getToolIcon(toolInvocation.toolName)}
          <span className="text-primary-700 dark:text-primary-300 font-medium">
            {displayToolName(toolInvocation.toolName)}
          </span>
        </div>
        {isDeveloper && (
          <Button
            size="sm"
            variant="ghost"
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-primary-700 dark:text-primary-300"
          >
            {isExpanded ? 'Collapse' : 'Expand'}
            <ChevronDown
              className={`ml-1 h-4 w-4 transition-transform ${isExpanded ? 'rotate-180' : ''}`}
            />
          </Button>
        )}
      </div>
      <div className="mt-2">
        {(toolInvocation.state === 'partial-call' || toolInvocation.state === 'call') && (
          <div className="text-primary-600 dark:text-primary-400 text-sm">
            <Loader2 className="mr-2 inline h-4 w-4 animate-spin" />
            {getToolStatusMessage(toolInvocation.toolName, toolInvocation.args)}
          </div>
        )}
      </div>
      {isExpanded && (
        <div className="mt-2 space-y-2">
          <div className="text-sm">
            <strong>Arguments:</strong>
            <pre className="bg-primary-50 dark:bg-primary-800 mt-1 rounded p-2 text-xs">
              {JSON.stringify(toolInvocation.args, null, 2)}
            </pre>
          </div>
          {toolInvocation.state === 'result' && (
            <div className="text-sm">
              <strong>Result:</strong>
              <pre className="bg-primary-50 dark:bg-primary-800 mt-1 rounded p-2 text-xs">
                {JSON.stringify(toolInvocation.result, null, 2)}
              </pre>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export const ConciergeMessage: React.FC<{
  message: Message
  conciergeData: ConciergeData | null
  originalQuery: string
}> = ({ message, conciergeData, originalQuery }) => {
  if (!conciergeData) return null

  return (
    <div className="concierge-message flex items-start justify-start gap-2 px-2 py-2 md:gap-4 md:px-5">
      <Avatar className="h-8 w-8 md:h-10 md:w-10">
        <AvatarImage src="/img/bubbletar-cora-gray.svg" alt="Cora Concierge" />
        <AvatarFallback>C</AvatarFallback>
      </Avatar>
      <div className="relative max-w-[85%] space-y-3 rounded-lg p-3 text-sm md:max-w-[80%]">
        <Markdown content={conciergeData.reasoning} />
        <div className="space-y-1">
          <h4 className="text-primary-600 dark:text-primary-400 text-sm font-medium">
            ✨ Enhanced Request:
          </h4>
          <div className="rounded-md bg-blue-200 p-2 dark:bg-blue-800">
            <div className="text-blue-900 dark:text-blue-100">
              <Markdown content={conciergeData.enhancedRequest} />
            </div>
          </div>
        </div>
        {conciergeData?.humanProvider && conciergeData?.humanModel && (
          <div className="mt-2 flex items-center justify-end space-x-2 text-xs text-gray-600 dark:text-gray-400">
            <span>
              Cora chose {conciergeData.humanProvider} {conciergeData.humanModel}
            </span>
            <Image
              src={conciergeData.providerLogo || ''}
              alt={`${conciergeData.humanProvider} logo`}
              className="h-4 w-4"
              width={16}
              height={16}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export const FollowupMessage: React.FC<{
  message: Message
  setInput: (input: string) => void
}> = ({ message, setInput }) => {
  const followupData = message.data as {
    mood_analysis?: string[]
    search_queries?: string[]
    fun_fact?: string
    follow_up_questions?: string[]
  }

  return (
    <div className="followup-message bg-primary-50 dark:bg-primary-900/30 space-y-4 rounded-lg p-4">
      {followupData.search_queries && followupData.search_queries.length > 0 && (
        <div className="learn-more-online rounded-lg bg-gradient-to-r from-blue-100 to-purple-100 p-4 shadow-sm dark:from-blue-900/30 dark:to-purple-900/30">
          <h4 className="text-primary-700 dark:text-primary-300 mb-3 flex items-center text-sm font-medium">
            <ExternalLink className="mr-2 h-5 w-5" />
            Learn More Online
          </h4>
          <div className="grid gap-3 sm:grid-cols-2">
            {followupData.search_queries.map((query, index) => {
              const luckyLink = generateLuckyLink(query)
              return (
                <a
                  key={index}
                  href={luckyLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-600 hover:bg-primary-100 dark:bg-primary-800 dark:text-primary-300 dark:hover:bg-primary-700 flex items-center justify-between rounded-lg bg-white p-3 text-sm font-medium transition-all duration-200 hover:shadow-md"
                >
                  <span className="flex items-center">
                    <span className="mr-2 text-xl">🔗</span>
                    {luckyLink.title}
                  </span>
                  <ExternalLink className="h-4 w-4 flex-shrink-0" />
                </a>
              )
            })}
          </div>
        </div>
      )}
      {/* Fun Fact section */}
      {followupData.fun_fact && (
        <div className="fun-fact bg-primary-100 dark:bg-primary-800/50 rounded-lg p-4">
          <h4 className="text-primary-700 dark:text-primary-300 mb-2 flex items-center text-sm font-medium">
            <Lightbulb className="mr-2 h-5 w-5" />
            Fun Fact
          </h4>
          <p className="text-primary-600 dark:text-primary-200 text-sm">
            {followupData.fun_fact}
          </p>
        </div>
      )}
      {/* Follow-up questions section */}
      {followupData.follow_up_questions && followupData.follow_up_questions.length > 0 && (
        <div className="follow-up-questions">
          <h4 className="text-primary-700 dark:text-primary-300 mb-3 text-sm font-medium">
            Continue the Conversation
          </h4>
          <div className="space-y-2">
            {followupData.follow_up_questions.map((question, index) => (
              <button
                key={index}
                onClick={() => setInput(question)}
                className="text-primary-600 hover:bg-primary-100 dark:bg-primary-800 dark:text-primary-300 dark:hover:bg-primary-700 w-full rounded-lg bg-white p-3 text-left text-sm font-medium transition-all duration-200 hover:shadow-md"
              >
                {question}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export const ToolMessage: React.FC<{
  message: Message
  nexusState: NexusState
}> = ({ message, nexusState }) => {
  return (
    <div className="tool-message flex items-start justify-start gap-2 px-2 py-2 md:gap-4 md:px-5">
      <Avatar className="h-8 w-8 md:h-10 md:w-10">
        <AvatarImage src="/img/bubbletar-cora-gray.svg" alt="Tool" />
        <AvatarFallback>T</AvatarFallback>
      </Avatar>
      <div className="relative w-[85%] space-y-3 rounded-lg p-3 text-sm md:max-w-[80%]">
        <Markdown content={message.content} />
        {message.toolInvocations && message.toolInvocations.length > 0 && (
          <div className="space-y-2">
            {message.toolInvocations.map((toolInvocation, index) => (
              <ToolInvocationDisplay
                key={index}
                toolInvocation={toolInvocation}
                nexusState={nexusState}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
