'use client'

import { AnimatePresence, motion } from 'framer-motion'
import { Moon, Sun, SunMoon } from 'lucide-react'
import { useTheme } from 'next-themes'

import { Button } from '@/app/components/ui/button'

export function DarkModeToggle() {
  const { theme, setTheme } = useTheme()

  const iconVariants = {
    initial: { opacity: 0, rotate: -90, scale: 0.5 },
    animate: { opacity: 1, rotate: 0, scale: 1 },
    exit: { opacity: 0, rotate: 90, scale: 0.5 }
  }

  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={() =>
        setTheme(theme === 'dark' ? 'light' : theme === 'light' ? 'system' : 'dark')
      }
      aria-label="Toggle color theme"
      className="relative h-10 w-10 rounded-full"
    >
      <AnimatePresence mode="wait" initial={false}>
        {theme === 'light' && (
          <motion.div key="sun" {...iconVariants} transition={{ duration: 0.2 }}>
            <Sun className="h-[1.2rem] w-[1.2rem]" />
          </motion.div>
        )}
        {theme === 'dark' && (
          <motion.div key="moon" {...iconVariants} transition={{ duration: 0.2 }}>
            <Moon className="h-[1.2rem] w-[1.2rem]" />
          </motion.div>
        )}
        {theme === 'system' && (
          <motion.div key="laptop" {...iconVariants} transition={{ duration: 0.2 }}>
            <SunMoon className="h-[1.2rem] w-[1.2rem]" />
          </motion.div>
        )}
      </AnimatePresence>
    </Button>
  )
}
