'use client'

import React, { useCallback, useEffect, useRef } from 'react'
import dynamic from 'next/dynamic'
import { UploadCtxProvider } from '@uploadcare/react-uploader'
import { AnimatePresence, motion } from 'framer-motion'
import { Loader, Paperclip, Send, Square, Trash2 } from 'lucide-react'
import { Accept, FileRejection, useDropzone } from 'react-dropzone'
import Textarea from 'react-textarea-autosize'

import { Button } from '@/app/components/ui/button'
import { useToast } from '@/app/components/ui/use-toast'
import { logger } from '@/app/lib/logging'
import { NexusState } from '@/app/lib/types'
import { uploadcareClientConfig } from '@/app/lib/uploadcare'
import { saveAttachment } from '@/app/si/attachments'

import '@uploadcare/react-uploader/core.css'

// Load the FileUploaderRegular component dynamically to avoid server-side hydration errors
const FileUploaderRegular = dynamic(
  () => import('@uploadcare/react-uploader').then(mod => mod.FileUploaderRegular),
  { ssr: false }
)

// React complains about the uc-upload-ctx-provider element not being defined
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'uc-upload-ctx-provider': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

// We accept what Google Gemini Pro supports
// https://firebase.google.com/docs/vertex-ai/input-file-requirements
const acceptedFileTypes = [
  'image/jpeg',
  'image/png',
  'image/webp',
  'image/gif',
  'application/pdf',
  'text/plain',
  'text/csv',
  'text/markdown',
  'audio/*',
  'video/*'
]

export function RequestInputForm({
  nexusState,
  onSubmit
}: {
  nexusState: NexusState
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}) {
  const {
    input,
    setInput,
    isLoading,
    stop,
    inputRef,
    formRef,
    onKeyDown,
    anonymousUserId,
    user,
    messageAttachments,
    setMessageAttachments
  } = nexusState

  const ctxProviderRef = useRef<InstanceType<UploadCtxProvider>>(null)
  const [isUploading, setIsUploading] = React.useState(false)
  const [isUploadcareDialogOpen, setIsUploadcareDialogOpen] = React.useState(false)

  const { toast } = useToast()

  const handleFileUpload = useCallback(
    async (file: File) => {
      const uploadCareAPI = ctxProviderRef.current?.getAPI()
      if (file && uploadCareAPI) {
        setIsUploading(true)
        try {
          await uploadCareAPI.addFileFromObject(file, { fileName: file.name })
        } catch (error) {
          logger.error('Error uploading file:', error)
          setIsUploading(false)
          toast({
            title: "Let's try that again",
            description:
              'We encountered a small hiccup while uploading your file. Your ideas are valuable - shall we give it another go?',
            duration: 5000
          })
        }
      }
    },
    [ctxProviderRef, toast]
  )

  const handlePaste = useCallback(
    async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
      const items = event.clipboardData.items
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (item.kind === 'file') {
          event.preventDefault()
          const file = item.getAsFile()
          if (file) {
            if (acceptedFileTypes.includes(file.type)) {
              await handleFileUpload(file)
            } else {
              toast({
                title: 'File type not supported',
                description: `${file.name} isn't a supported file type. Try a PDF, image, or text file.`,
                duration: 5000
              })
            }
          }
        }
      }
    },
    [handleFileUpload, toast]
  )

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      acceptedFiles.forEach(file => handleFileUpload(file))
    },
    [handleFileUpload]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    disabled: isUploadcareDialogOpen,
    accept: acceptedFileTypes.reduce((acc, type) => {
      acc[type] = []
      return acc
    }, {} as Accept),
    onDropRejected: (fileRejections: FileRejection[]) => {
      fileRejections.forEach(rejection => {
        toast({
          title: 'File type not supported',
          description: `${rejection.file.name} is not a supported file type. Try a PDF, image, or text file.`,
          duration: 5000
        })
      })
    }
  })

  const handleFileUploadSuccess = useCallback(
    (file: any) => {
      const attachmentData = JSON.parse(JSON.stringify(file))
      saveAttachment(attachmentData, anonymousUserId)
      setIsUploading(false)
      setMessageAttachments(prev => [...prev, attachmentData])
      setIsUploadcareDialogOpen(false)
    },
    [anonymousUserId, setMessageAttachments]
  )

  const handlePaperclipClick = useCallback(() => {
    const uploadCareAPI = ctxProviderRef.current?.getAPI()
    if (uploadCareAPI) {
      setIsUploadcareDialogOpen(true)
      uploadCareAPI.initFlow()
    }
  }, [ctxProviderRef])

  const handleActivityChange = useCallback(
    (event: CustomEvent<{ activity: string | null }>) => {
      setIsUploadcareDialogOpen(event.detail.activity !== null)
    },
    []
  )

  const handleRemoveMessageAttachment = useCallback(
    (index: number) => {
      const newAttachments = [...messageAttachments]
      newAttachments.splice(index, 1)
      setMessageAttachments(newAttachments)
    },
    [messageAttachments, setMessageAttachments]
  )

  useEffect(() => {
    const ctxProvider = ctxProviderRef.current
    if (ctxProvider) {
      ctxProvider.addEventListener('activity-change', handleActivityChange)
    }
    return () => {
      if (ctxProvider) {
        ctxProvider.removeEventListener('activity-change', handleActivityChange)
      }
    }
  }, [handleActivityChange])

  return (
    <div id="chat-form-container" className="bg-base-100 p-4">
      {/* Attachments grid and loading indicator */}
      <AnimatePresence>
        {(messageAttachments.length > 0 || isUploading) && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="mb-4 overflow-hidden"
          >
            <div className="overflow-x-auto">
              <div className="flex space-x-2">
                {messageAttachments.map((attachment, index) => (
                  <div
                    key={index}
                    className="flex min-w-[150px] items-center justify-between rounded-lg bg-base-200 p-2"
                  >
                    <div className="flex items-center space-x-2 overflow-hidden">
                      <span className="truncate text-sm text-base-content">
                        {attachment.name}
                      </span>
                    </div>
                    <button
                      onClick={() => handleRemoveMessageAttachment(index)}
                      className="btn btn-ghost btn-xs ml-2 text-base-content hover:text-error"
                      aria-label={`Delete ${attachment.name}`}
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                ))}
                {isUploading && (
                  <div className="flex min-w-[150px] items-center justify-center rounded-lg bg-base-200 p-2">
                    <Loader className="h-5 w-5 animate-spin text-base-content" />
                    <span className="ml-2 text-sm text-base-content">Uploading...</span>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Chat form with drag and drop support */}
      <div
        {...getRootProps()}
        className={`mx-auto flex max-w-full flex-col rounded-lg transition-all duration-300 ${
          isDragActive && !isUploadcareDialogOpen
            ? 'border-2 border-dashed border-primary bg-base-200'
            : ''
        }`}
      >
        <form id="chat-form" ref={formRef} onSubmit={onSubmit} className="flex flex-col">
          <input {...getInputProps()} />
          <Textarea
            ref={inputRef}
            maxRows={10}
            onKeyDown={onKeyDown}
            onPaste={handlePaste}
            rows={1}
            name="message"
            autoFocus
            spellCheck={false}
            autoComplete="off"
            placeholder="Your request..."
            value={input}
            onChange={e => setInput(e.target.value)}
            className="textarea textarea-bordered mb-2 min-h-12 w-full resize-none focus:border-primary focus:ring-1 focus:ring-primary"
            aria-label="Chat input"
          />

          <div className="flex items-center">
            <style type="text/css">{`
              /* Hide the Uploadcare copyright and file uploader icon (we use the paperclip button instead) */
              uc-copyright {
                display: none !important;
              }
              uc-file-uploader-regular {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                white-space: nowrap;
                border: 0;
              }
            `}</style>
            <uc-upload-ctx-provider
              ref={ctxProviderRef}
              ctx-name="Cora Conversation Attachments"
            />
            <FileUploaderRegular
              ctxName="Cora Conversation Attachments"
              multipleMax={10}
              useCloudImageEditor={false}
              multiple={true}
              maxLocalFileSizeBytes={50_000_000}
              sourceList="local, camera, dropbox, gdrive, instagram"
              classNameUploader="uc-light uc-purple" // Remove uc-light when we have dark mode working
              pubkey={uploadcareClientConfig.publicKey}
              onFileUploadSuccess={handleFileUploadSuccess}
              metadata={{
                user_id: user?.emailAddresses[0]?.emailAddress || anonymousUserId || ''
              }}
              accept={acceptedFileTypes.join(',')}
            />
            <Button
              variant="ghost"
              size="icon"
              type="button"
              onClick={handlePaperclipClick}
              aria-label="Attach files"
              className="btn btn-circle btn-ghost"
            >
              <Paperclip />
            </Button>

            <Button
              type="submit"
              className="btn btn-primary ml-auto gap-1.5"
              onClick={isLoading ? stop : undefined}
              aria-label={isLoading ? 'Stop generating' : 'Send message'}
            >
              <span className="hidden sm:inline">{isLoading ? 'Stop' : 'Send'}</span>
              {isLoading ? <Square size={20} /> : <Send size={20} />}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
