'use client'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Attachment as ExperimentalAttachment } from '@ai-sdk/ui-utils'
import { useUser } from '@clerk/nextjs'
import { Message } from 'ai'
import { useChat } from 'ai/react'
import { AnimatePresence, motion } from 'framer-motion'
import { nanoid } from 'nanoid'
import { useTheme } from 'next-themes'

import { useConversations } from '@/app/context/ConversationContext'
import { handleError } from '@/app/lib/errors'
import { logger } from '@/app/lib/logging'
import { Attachment, ConciergeData, Conversation, NexusState } from '@/app/lib/types'
import { saveConversation } from '@/app/si/conversations'
import { useEnterSubmit } from './hooks/use-enter-submit'
import { useSmartAutoscroll } from './hooks/use-smart-autoscroll'
import {
  AIMessage,
  ConciergeMessage,
  FollowupMessage,
  ToolMessage,
  UserMessage
} from './messages'
import { RequestInputForm } from './request-input-form'
import { Sidebar } from './sidebar'
import { Topbar } from './topbar'
import { ScrollArea } from './ui/scroll-area'
import ThemeImage from './ui/theme-image'

export default function Nexus() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const { formRef, onKeyDown } = useEnterSubmit()
  const [conciergeLoading, setConciergeLoading] = useState(false)
  const [conciergeData, setConciergeData] = useState<ConciergeData | null>(null)
  const [conciergeStatus, setConciergeStatus] = useState<string | null>(null)
  const [conversationId, setConversationId] = useState<string | null>(null)
  const [messageAttachments, setMessageAttachments] = useState<any[]>([])
  const [anonymousUserId, setAnonymousUserId] = useState<string | undefined>(undefined)

  const { isSignedIn, user, isLoaded: isUserLoaded } = useUser()

  const manageAnonymousUserId = useCallback(() => {
    if (isSignedIn && user) {
      localStorage.removeItem('anonymousUserId')
      logger.trace('Using logged-in user ID', {
        userEmail: user.emailAddresses[0].emailAddress
      })
      setAnonymousUserId(undefined)
    } else {
      const storedAnonymousUserId =
        localStorage.getItem('anonymousUserId') ?? `anon:${nanoid()}`
      localStorage.setItem('anonymousUserId', storedAnonymousUserId)
      logger.trace('Using anonymous user ID', storedAnonymousUserId)
      setAnonymousUserId(storedAnonymousUserId)
    }
  }, [isSignedIn, user])

  useEffect(() => {
    manageAnonymousUserId()
  }, [manageAnonymousUserId])

  // Resize sidebar based on window width
  useEffect(() => {
    // Set initial window width
    setWindowWidth(window.innerWidth)

    // Update sidebar state based on initial width
    if (window.innerWidth < 640) {
      setIsSidebarOpen(false)
    }

    // Set up resize listener
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
      setIsSidebarOpen(window.innerWidth >= 640)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  /**
   * Calls the concierge API to enhance the user's input.
   * Updates loading state and handles errors.
   * Returns the enhanced request data.
   */
  const callConcierge = async (userInput: string): Promise<ConciergeData> => {
    setConciergeStatus('Enhancing your request...')
    setConciergeLoading(true)
    logger.debug('Calling concierge', { userInput })

    try {
      const response = await fetch('/api/concierge', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userRequest: userInput,
          hasAttachments: messageAttachments.length > 0
        })
      })

      if (!response.ok) {
        throw new Error('Concierge request failed with status: ' + response.status)
      }

      const data: ConciergeData = await response.json()
      logger.trace('Concierge response received', { data })
      return data
    } catch (error) {
      handleError(error, 'Concierge')
    } finally {
      setConciergeLoading(false)
      setConciergeStatus(null)
      logger.trace('Concierge call completed')
    }

    // return an empty ConciergeData so that the concierge data is not null, and won't be called again
    return {
      enhancedRequest: '',
      reasoning: '',
      responseStrategy: 'complex',
      mood: '',
      moodEmoji: '',
      conversationTitle: input.trim().split(' ').slice(0, 5).join(' ')
    } as ConciergeData
  }

  /**
   * Vercel's useChat hook that handles the chat functionality
   */
  const { messages, setMessages, input, setInput, isLoading, append, stop, reload } = useChat({
    api: '/api/agent_vercel',
    maxSteps: 5,
    sendExtraMessageFields: true,
    keepLastMessageOnError: true,
    body: {
      user: user
    },
    onError: (error: Error) => {
      handleError(error, 'Chat')
    },
    onFinish: async (message: Message) => {
      // Save the conversation
      let updatedMessages = messages
      setMessages(prevMessages => {
        updatedMessages = [...prevMessages]
        return updatedMessages
      })

      // Call followups API
      setConciergeStatus('Generating followups...')
      setConciergeLoading(true)
      try {
        const followupMessage = await callFollowupsAPI(updatedMessages)
        if (followupMessage) {
          setMessages(prevMessages => [...prevMessages, followupMessage])
          // Update updatedMessages to include the followup message
          updatedMessages = [...updatedMessages, followupMessage]
        }
      } catch (error) {
        logger.error('Error calling followups API', { error })
      } finally {
        setConciergeLoading(false)
        setConciergeStatus(null)
      }

      // Save conversation
      try {
        await saveMemoryConversation(updatedMessages)
      } catch (error) {
        logger.error('Error saving conversation', { error })
      }
    }
  })

  // setConciergeData does not always execute, so we store conciergeResult outside of the function
  let conciergeResult: ConciergeData | null = null

  /**
   * Handles the form submission for user input.
   * Enhances the user's input using the concierge API, if available.
   * Appends both user and system messages to the chat.
   * Resets the input field after submission.
   */
  const ourHandleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (input.trim() === '') return

    logger.debug('Form submission', { input })

    // Create ExperimentalAttachment from the messageAttachments array
    const experimentalAttachments: ExperimentalAttachment[] = messageAttachments.map(
      (attachment: Attachment) => ({
        name: attachment.name,
        url: attachment.cdnUrl,
        contentType: attachment.mimeType
      })
    )

    const userMessage: Message = {
      id: nanoid(),
      createdAt: new Date(),
      role: 'user',
      content: input,
      data: { attachments: messageAttachments }, // store the full attachment object for later use
      experimental_attachments: experimentalAttachments
    }

    if (!conciergeData) {
      // Immediately display the user message
      setMessages(prevMessages => [...prevMessages, userMessage])

      logger.trace('First message, calling concierge')
      conciergeResult = await callConcierge(input)
      setConciergeData(conciergeResult)
      logger.trace('Concierge data set', { conciergeResult })

      const conciergeMessage: Message = {
        id: nanoid(),
        createdAt: new Date(),
        role: 'system',
        content: `We enhanced your request to get a better answer. Here is the enhanced request:\n${conciergeResult.enhancedRequest}`,
        data: JSON.parse(JSON.stringify(conciergeResult)) // hack to force a json value
      }

      // Append the concierge message, and submit the form
      append(conciergeMessage, {
        body: {
          conciergeData: conciergeResult,
          user: user
        }
      })
    } else {
      logger.trace('Subsequent message, reusing concierge data')
      // Subsequent messages, reuse conciergeData
      append(userMessage, {
        body: {
          conciergeData: conciergeData,
          user: user
        }
      })
    }

    logger.trace('Message appended', { messageId: userMessage.id })
    // Clear the input field and attachments for the next message
    setInput('')
    setMessageAttachments([])
  }

  /**
   * Smart auto-scrolling for the chat interface.
   * Auto-scrolls to new messages, pauses on user interaction.
   * Resumes scrolling when user returns to bottom.
   */
  const {
    scrollRef,
    messagesEndRef,
    isAtBottom,
    isUserScrolling,
    scrollToBottom,
    setIsUserScrolling
  } = useSmartAutoscroll()

  useEffect(() => {
    if (isAtBottom && !isUserScrolling) {
      scrollToBottom()
    }
  }, [messages, isAtBottom, isUserScrolling, scrollToBottom])

  const nexusState: NexusState = {
    // Chat-related (from useChat)
    messages,
    setMessages,
    input,
    setInput,
    append,
    isLoading,
    stop,
    reload,

    // UI and form references
    formRef,
    inputRef,
    onKeyDown,

    // User and session
    user,
    isSignedIn,
    isUserLoaded,
    anonymousUserId,

    // Conversation management
    conversationId,
    setConversationId,

    // Attachments
    messageAttachments,
    setMessageAttachments,

    // Concierge-related
    conciergeData,
    setConciergeData,
    conciergeLoading,
    conciergeStatus,
    setConciergeStatus,

    // Sidebar state
    isSidebarOpen,
    setIsSidebarOpen
  }

  const { conversations, setConversations } = useConversations()

  const saveMemoryConversation = async (messages: Message[]) => {
    try {
      let localConversationId = conversationId
      let isNewConversation = false
      if (!localConversationId) {
        localConversationId = nanoid()
        setConversationId(localConversationId)
        isNewConversation = true
        logger.trace('Created new conversation ID', { conversationId: localConversationId })
      }
      if (!conciergeData) {
        logger.warn('No concierge data found')
      }

      if (!anonymousUserId && !user) {
        logger.error('No user ID found when trying to save conversation')
        return
      }

      const conversation: Conversation = {
        id: localConversationId,
        messages,
        createdAt: new Date(),
        userId: anonymousUserId, // will be undefined if not logged in
        // Weird stuff happens with conciergeData not being set, so try both.
        title:
          conciergeData?.conversationTitle ||
          conciergeResult?.conversationTitle ||
          'Untitled Conversation'
      }

      logger.debug('Saving conversation', {
        conversationId: conversation.id,
        messageCount: messages.length,
        userId: anonymousUserId,
        isNewConversation
      })
      await saveConversation(conversation, anonymousUserId)

      // Only update the conversations list if it's a new conversation
      if (isNewConversation) {
        setConversations(prevConversations => [conversation, ...prevConversations])
        logger.trace('New conversation added to the list')
      }

      logger.trace('Conversation saved successfully')
    } catch (error) {
      handleError(error, 'Conversation Saving')
    }
  }

  const renderMessage = (m: Message) => {
    let messageContent
    switch (m.role) {
      case 'user':
        messageContent = <UserMessage message={m} nexusState={nexusState} />
        break
      case 'system':
        messageContent = (
          <ConciergeMessage
            message={m}
            conciergeData={m.data as unknown as ConciergeData}
            originalQuery={input}
          />
        )
        break
      case 'tool':
        if (m.content === 'followup') {
          messageContent = <FollowupMessage message={m} setInput={setInput} />
        } else {
          messageContent = <ToolMessage message={m} nexusState={nexusState} />
        }
        break
      default: // assistant
        if (m.toolInvocations && m.toolInvocations.length > 0) {
          messageContent = <ToolMessage message={m} nexusState={nexusState} />
        } else {
          messageContent = <AIMessage message={m} nexusState={nexusState} />
        }
    }

    return (
      <motion.div
        key={m.id}
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0, opacity: 0 }}
        transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }}
        style={{
          transformOrigin: 'center left'
        }}
      >
        {messageContent}
      </motion.div>
    )
  }

  const logoVariants = {
    hidden: {
      opacity: 0,
      scale: 0.9,
      y: 20,
      filter: 'blur(10px)'
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      filter: 'blur(0px)',
      transition: {
        duration: 1.5,
        ease: 'easeOut'
      }
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      y: -20,
      filter: 'blur(10px)',
      transition: {
        duration: 1.2,
        ease: 'easeIn'
      }
    }
  }
  const { theme, setTheme } = useTheme()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null // or a loading spinner
  }

  return (
    <div className="flex min-h-screen w-full flex-col bg-base-100">
      <Topbar nexusState={nexusState} />

      <div id="nexus" className="flex flex-1 overflow-hidden bg-gray-100 dark:bg-gray-900">
        <Sidebar nexusState={nexusState} />
        <div className="bg-light dark:bg-dark relative flex flex-1 flex-col overflow-hidden">
          <div id="chat-message-container" className="relative flex-1 overflow-hidden">
            <AnimatePresence>
              {messages.length === 0 && (
                <motion.div
                  className="absolute inset-0 flex items-center justify-center"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={logoVariants}
                >
                  <ThemeImage
                    lightSrc="/img/bubbletar-cora-purple.svg"
                    darkSrc="/img/bubbletar-cora-white.svg"
                    alt="Cora Logo"
                    width={100}
                    height={100}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            {messages.length > 0 && (
              <div className="h-full overflow-y-auto">
                <ScrollArea
                  ref={scrollRef}
                  className="flex h-full w-full flex-col"
                  onMouseDown={() => setIsUserScrolling(true)}
                  onWheel={() => setIsUserScrolling(true)}
                >
                  <div className="mx-auto w-full max-w-4xl sm:px-6 md:px-6">
                    <AnimatePresence>{messages.map(renderMessage)}</AnimatePresence>
                  </div>
                  <div ref={messagesEndRef} className="h-24" />
                </ScrollArea>
              </div>
            )}
          </div>
          {/* Chat input form */}
          <div className="mx-auto w-full max-w-4xl sm:px-6 sm:pb-6 md:px-6 md:pb-6">
            <div className="overflow-hidden rounded-xl border border-gray-200 bg-white">
              <RequestInputForm nexusState={nexusState} onSubmit={ourHandleSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const callFollowupsAPI = async (messages: Message[]): Promise<Message | null> => {
  try {
    const response = await fetch('/api/followups', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ messages })
    })

    if (!response.ok) {
      throw new Error('Followups request failed with status: ' + response.status)
    }

    const data = await response.json()
    return {
      id: nanoid(),
      createdAt: new Date(),
      role: 'tool',
      content: 'followup',
      data: data
    }
  } catch (error) {
    handleError(error, 'Followups')
    return null
  }
}
